<template>
  <!-- logo -->
  <div class="d-flex align-center justify-center h-full ghd-base-color-black ghd-text-white" style="padding: 20px">
    <div class="text-center" style="width: 100%">
      <img
        height="auto"
        width="200"
        src="/images/logo-oro.png"
        class="mb-16"
      />

      <div class="text-center">
        <h1 class="text-xlarge font-weight-semibold ghd-ITCAvant" style="line-height: 1">
          {{ appName }}
        </h1>
        <span class="text-medium">
          Che il tuo prodotto sia ancora in garanzia o meno, siamo qui per aiutarti.
        </span>
      </div>
      <div class="text-center mt-16">
        <span class="mt-3 text-md">
          Seleziona la categoria di appartenenza e crea il tuo account.
        </span>
        <div class="d-flex justify-center mt-3">
          <v-btn
            tile

            class="align-items-center mr-2 btn-gold button-xxl text-medium"
            style="height: 60px !important;"
            to="registration/privato"
          >
            Cliente Privato
          </v-btn>
          <v-btn
            tile
            to="registration/salone"
            style="height: 60px !important;"
            class="w-100 btn-gold button-xxl text-medium"
          >
            Salone
          </v-btn>
        </div>
        <div class="mt-10">
          <p>Sei già registrato?</p>
          <v-btn
            x-large
            tile
            outlined
            :to="{name: 'auth-login'}"
            class="ghd-text-white"
          >
            Effettua il login
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import themeConfig from '@themeConfig'
import store from '@/store'

export default {
  name: 'HomePage',

  setup() {
    const resetTextMessages = () => {
      store.commit('assistance/setHeaderTextMessages', 'Informazioni generali')
      store.commit('assistance/setSubHeaderTextMessages', '')
    }
    resetTextMessages()

    return {
      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style scoped>

</style>
